<template>
	<div class="d-flex flex-column align-items-center">
		<span class="" v-if="buttonField?.extra_parameters?.help_text"> {{ buttonField.extra_parameters.help_text }} </span>
		<div class="d-block w-100">
			<button type="submit" @click.prevent="submit" :disabled="submitStatus" class="btn form-btn w-100" :class="buttonField?.extra_parameters?.styles || 'btn btn-success'">
				{{ buttonField?.help }}<span v-if="submitStatus" class="spinner-border text-light spinner-border-sm ms-1" role="status"></span>
			</button>
		</div>
	</div>
</template>
<script>
// Store
import { dynamicFormStore } from "../../stores/store";
import { mapStores, mapState } from 'pinia'

export default {
    methods: {
        submit() {
            this.$emit('submit')
        }
    },
  computed: {
      ...mapStores(dynamicFormStore),
      ...mapState(dynamicFormStore, ['buttonField', 'submitStatus'])
  }
}
</script>